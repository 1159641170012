import { sessionModel, SessionModel } from "./session";
import { websocketModel, WebsocketModel } from "./websocket";
import { persist } from "easy-peasy";

export interface Injections {}

export interface StoreModel {
  session: SessionModel;
  websocket: WebsocketModel;
}

export const storeModel: StoreModel = {
  session: sessionModel,
  websocket: persist(websocketModel, {
    storage: "localStorage",
    allow: ["token"],
  }),
};
