import React, {
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import "./Session.css";
import { assertNever } from "../util/types";
import {
  ActivityRating,
  ActivityType,
  SessionActivity,
  SessionCurrentActivity,
} from "../model";
import {
  ActivityCreate,
  ActivityCreateProps,
} from "../components/ActivityCreate";
import { ActivityList } from "../components/ActivityList";
import { useStoreActions, useStoreState } from "../hooks/store";

export const Session: React.FunctionComponent = ({}) => {
  // const [state, dispatch] = useReducer(SessionReducer, { activities: [] });
  const currentActivity = useStoreState(
    (state) => state.session.currentActivity,
  );
  const activitySuggestions = useStoreState(
    (state) => state.session.activitySuggestions,
  );
  const activities = useStoreState((state) => state.session.activities);
  const createActivity = useStoreActions(
    (actions) => actions.session.createActivity,
  );
  const saveCurrentActivity = useStoreActions(
    (actions) => actions.session.saveCurrentActivity,
  );
  const updateCurrentActivity = useStoreActions(
    (actions) => actions.session.updateCurrentActivity,
  );

  // const activityOnCreate = useCallback(
  //   () => dispatch({ type: "activity:create" }),
  //   [dispatch],
  // );
  //
  // const activityOnSave = useCallback(
  //   () => dispatch({ type: "activity:save" }),
  //   [dispatch],
  // );
  //
  // const activityOnSet: ActivityCreateProps["onSet"] = useCallback(
  //   (activityEdit) => {
  //     if (activityEdit.name !== undefined) {
  //       dispatch({ type: "activity:setText", value: activityEdit.name });
  //     }
  //     if (activityEdit.type !== undefined) {
  //       dispatch({ type: "activity:setType", value: activityEdit.type });
  //     }
  //     if (activityEdit.rating !== undefined) {
  //       dispatch({ type: "activity:setRating", value: activityEdit.rating });
  //     }
  //   },
  //   [dispatch],
  // );

  return (
    <div className="Session">
      <ActivityCreate
        activity={currentActivity}
        activitySuggestions={activitySuggestions}
        onCreate={createActivity}
        onSave={saveCurrentActivity}
        onSet={updateCurrentActivity}
      />
      <ActivityList activities={activities} />
    </div>
  );
};

interface SessionState {
  currentActivity?: SessionCurrentActivity;
  activities: SessionActivity[];
}

type SessionAction =
  | { type: "activity:create" }
  | { type: "activity:save" }
  | { type: "activity:setText"; value: string }
  | { type: "activity:setType"; value: ActivityType }
  | { type: "activity:setRating"; value: ActivityRating };

const SessionReducer: React.Reducer<SessionState, SessionAction> = (
  state: SessionState,
  action: SessionAction,
): SessionState => {
  switch (action.type) {
    case "activity:create": {
      return {
        ...state,
        currentActivity: {
          name: "",
          type: ActivityType.Plain,
        },
      };
    }
    case "activity:save": {
      // TODO: Submit, maybe add temp version to list?
      //       For now, just add it locally
      if (!state.currentActivity) {
        console.error(
          "SessionReducer: activity:save called without current activity",
        );
        return { ...state };
      }
      if (state.currentActivity.rating === undefined) {
        console.error(
          "SessionReducer: activity:save called without current activity rating",
        );
        return { ...state };
      }
      return {
        ...state,
        currentActivity: undefined,
        activities: [
          {
            id: "FAKEID",
            ratings: { FAKECURRENTUSER: state.currentActivity.rating },
            ...state.currentActivity,
          },
          ...state.activities,
        ],
      };
    }
    case "activity:setText": {
      if (!state.currentActivity) {
        console.error(
          "SessionReducer: activity:setText called without current activity",
        );
        return { ...state };
      }
      return {
        ...state,
        currentActivity: { ...state.currentActivity, name: action.value },
      };
    }
    case "activity:setType": {
      if (!state.currentActivity) {
        console.error(
          "SessionReducer: activity:setText called without current activity",
        );
        return { ...state };
      }
      return {
        ...state,
        currentActivity: { ...state.currentActivity, type: action.value },
      };
    }
    case "activity:setRating": {
      if (!state.currentActivity) {
        console.error(
          "SessionReducer: activity:setText called without current activity",
        );
        return { ...state };
      }
      return {
        ...state,
        currentActivity: { ...state.currentActivity, rating: action.value },
      };
    }
    default: {
      assertNever(action);
    }
  }
};
