import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useEffect } from "react";
import { useStoreActions, useStoreState } from "../hooks/store";
import "./Auth.css";
import { useStoreRehydrated } from "easy-peasy";

export interface AuthProps {
  render?: () => React.ReactElement<any, any> | null;
}

export const Auth: React.FunctionComponent<AuthProps> = ({ render }) => {
  const isStoreRehydrated = useStoreRehydrated();
  const authState = useStoreState((state) => state.websocket.authState);
  const auth = useStoreActions((actions) => actions.websocket.auth);
  const tryOpportunisticAuth = useStoreActions(
    (actions) => actions.websocket.tryOpportunisticAuth,
  );

  // Attempt an automatic auth using stored token if possible
  useEffect(() => {
    console.log("auth callback opportunity");
    if (isStoreRehydrated) {
      console.log("auth callback opportunity attempting");
      tryOpportunisticAuth({ activitySession: document.location.pathname });
    }
  }, [isStoreRehydrated]);

  const [username, setUsername] = useState("");

  const onUserChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  }, []);

  const onAuthSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!username) {
        return;
      }
      auth({ username: username, activitySession: document.location.pathname });
    },
    [username, auth],
  );

  return authState === "authed" ? (
    render ? (
      render()
    ) : null
  ) : (
    <div className="Auth">
      {!isStoreRehydrated || authState === "authing" ? (
        <>
          <h2>Loading...</h2>
        </>
      ) : (
        <>
          <h2>Log in to continue</h2>
          <p>Well, just pick a name and make it consistent.</p>
          <form onSubmit={onAuthSubmit}>
            <label htmlFor="auth-username">Username</label>
            <input id="auth-username" type="text" onChange={onUserChange} />
          </form>
        </>
      )}
    </div>
  );
};
