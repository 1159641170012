import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { ActivityRating, SessionActivity } from "../model";
import { ActivityDisplay } from "./ActivityDisplay";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./ActivityList.css";

const _HARMONIC_MEAN_RATING_SCORES: Record<ActivityRating, number> = {
  love: 4,
  like: 3,
  would: 2,
  // Gap between these to give extra weight to no votes
  no: 0,
};

function harmonicMean(arr: number[]): number {
  if (arr.length < 1) return 0;
  if (arr.some((value) => value < 0)) {
    throw new Error("harmonicMean does not support negative values");
  }
  return arr.length / arr.reduce((acc, val) => acc + 1 / val, 0);
}

function harmonicMeans<T extends SessionActivity>(arr: T[]): T[] {
  return arr
    .map((activity) => {
      return {
        activity,
        scores: Object.values(activity.ratings).map(
          (rating) => _HARMONIC_MEAN_RATING_SCORES[rating],
        ),
        scoreCount: Object.keys(activity.ratings).length,
      };
    })
    .sort((a, b) => {
      let delta = harmonicMean(b.scores) - harmonicMean(a.scores);
      if (delta === 0) {
        return b.scoreCount - a.scoreCount;
      }
      return delta;
    })
    .map((entry) => entry.activity);
}

interface ActivityListProps {
  activities: SessionActivity[];
}

export const ActivityList: React.FunctionComponent<ActivityListProps> = ({
  activities,
}) => {
  const [isSorted, setIsSorted] = useState(false);

  const sortedActivities = useMemo(() => {
    return isSorted ? harmonicMeans(activities) : activities;
  }, [activities, isSorted]);

  const onSortChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setIsSorted(event.target.checked),
    [setIsSorted],
  );

  return (
    <div className="ActivityList">
      <div className="ActivityList-menubar">
        <label>
          <span>Sort</span>
          <Toggle
            className="ActivityList-toggle"
            defaultChecked={isSorted}
            onChange={onSortChanged}
          />
        </label>
      </div>
      {sortedActivities.map((activity) => (
        <ActivityDisplay key={activity.id} activity={activity} />
      ))}
    </div>
  );
};
