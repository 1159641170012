import React, { useCallback } from "react";
import "./App.css";
import { Session } from "./pages/Session";
import { StoreProvider } from "easy-peasy";
import { store } from "./store";
import { Auth } from "./components/Auth";
import { Home } from "./pages/Home";

function App() {
  const page = window.location.pathname.replace(/(^\/+)|(\/+$)/g, "");

  const goDirectlyToHomeDoNotPassGo = useCallback(() => {
    window.location.pathname = "/";
    return null;
  }, []);

  return (
    <div className="App">
      <StoreProvider store={store}>
        {page === "" ? (
          <Home />
        ) : page.startsWith("session-") ? (
          <Auth render={() => <Session />}></Auth>
        ) : (
          goDirectlyToHomeDoNotPassGo()
        )}
      </StoreProvider>
    </div>
  );
}

export default App;
