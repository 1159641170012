import React, { useEffect, useMemo } from "react";
import { ActivityRating, SessionActivity } from "../model";
import clsx from "clsx";
import "./ActivityRatingBar.css";

const RatingOptions: Record<ActivityRating, string> = {
  [ActivityRating.No]: "No",
  [ActivityRating.Would]: "Would",
  [ActivityRating.Like]: "Like",
  [ActivityRating.Love]: "Love",
};

interface ActivityRatingBarProps {
  rating?: ActivityRating;
  ratings?: SessionActivity["ratings"];
  onChange?: (rating: ActivityRating) => void;
}

export const ActivityRatingBar: React.FunctionComponent<
  ActivityRatingBarProps
> = ({ rating: selectedRating, ratings, onChange }) => {
  const ratingTotals: Record<ActivityRating, number> | undefined =
    useMemo(() => {
      if (!ratings) return undefined;
      const totals = {
        love: 0,
        like: 0,
        would: 0,
        no: 0,
      };
      for (let rating of Object.values(ratings)) {
        totals[rating] += 1;
      }
      return totals;
    }, [ratings]);
  return (
    <div className="ActivityRatingBar">
      {Object.entries(RatingOptions).map(([rating, label]) => (
        <button
          key={rating}
          className={clsx(
            "ActivityRatingBarBlock",
            `ActivityRatingBarBlock--${rating}`,
            rating === selectedRating && "ActivityRatingBarBlock--selected",
          )}
          onClick={() => onChange && onChange(rating as ActivityRating)}
        >
          {ratingTotals && (
            <>
              {ratingTotals[rating as ActivityRating]}
              <br />
            </>
          )}
          {label}
        </button>
      ))}
    </div>
  );
};
