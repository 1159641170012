import React, { useMemo } from "react";
import { Activity, ActivityType } from "../model";
import "./ActivityIcon.css";
import clsx from "clsx";

export interface ActivityIconProps extends React.HTMLProps<HTMLDivElement> {
  activity: Activity;
  small?: boolean;
}

const DEFAULT_ACTIVITY_ICON = "/static/images/activity_plain.png";
const DEFAULT_ACTIVITY_ICONS: Record<ActivityType, string> = {
  [ActivityType.Plain]: "/static/images/activity_plain.png",
  [ActivityType.Steam]: "/static/images/activity_steam.svg",
};

export const ActivityIcon: React.FunctionComponent<ActivityIconProps> = ({
  activity,
  small,
  className,
  ...props
}) => {
  const [imageSrc, isPlaceholder] = useMemo(() => {
    const icon = small
      ? activity.icon || activity.image
      : activity.image || activity.icon;
    if (icon) {
      return [icon, false];
    } else {
      return [
        DEFAULT_ACTIVITY_ICONS[activity.type] || DEFAULT_ACTIVITY_ICON,
        true,
      ];
    }
  }, [activity.icon, activity.type]);
  return (
    <div
      className={clsx(
        className,
        "ActivityIcon",
        isPlaceholder ? "ActivityIcon--placeholder" : "ActivityIcon--image",
      )}
      {...props}
    >
      <img src={imageSrc} alt="" />
    </div>
  );
};
