import React, {
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { assertNever } from "../util/types";
import "./ActivityDisplay.css";
import {
  Activity,
  ActivityRating,
  ActivityType,
  SessionActivity,
} from "../model";
import { ActivityRatingBar } from "./ActivityRatingBar";
import { useStoreActions, useStoreState } from "../hooks/store";
import { ActivityIcon } from "./ActivityIcon";

interface ActivityDisplayProps {
  activity: SessionActivity;
}

export const ActivityDisplay: React.FunctionComponent<ActivityDisplayProps> = ({
  activity,
}) => {
  const userId = useStoreState((state) => state.websocket.token);
  const rateActivity = useStoreActions(
    (actions) => actions.session.rateActivity,
  );

  const selectedRating = useMemo(() => {
    return activity.ratings[userId as any] || undefined;
  }, [activity, userId]);

  const onRatingChange = useCallback(
    (rating: ActivityRating) => {
      rateActivity({ activityId: activity.id, rating: rating });
    },
    [activity],
  );

  return (
    <div className="ActivityDisplay">
      {/*{JSON.stringify(activity, undefined, 4)}*/}
      <div className="ActivityDisplay-icon">
        <ActivityIcon className="ActivityDisplay-icon" activity={activity} />
      </div>
      <div className="ActivityDisplay-details">
        <div className="ActivityDisplay-name">{activity.name}</div>
        <div className="ActivityDisplay-ratings">
          <ActivityRatingBar
            rating={selectedRating}
            ratings={activity.ratings}
            onChange={onRatingChange}
          />
        </div>
      </div>
    </div>
  );
};
