import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import { io, Socket } from "socket.io-client";
import { Injections } from ".";

export interface WebsocketModel {
  socket: Socket | undefined;
  authState: "pending" | "authing" | "error" | "authed";
  authError: string | undefined;
  token: string | undefined;

  _authLoading: Action<WebsocketModel, void>;
  _authSuccess: Action<WebsocketModel, { socket: Socket; token: string }>;
  _authError: Action<WebsocketModel, string>;
  auth: Thunk<
    WebsocketModel,
    { activitySession: string; username: string },
    Injections
  >;
  tryOpportunisticAuth: Thunk<
    WebsocketModel,
    { activitySession: string },
    Injections
  >;
}

function getSocketURL(activitySession: string) {
  let host =
    process.env.REACT_APP_SOCKET_URL ||
    `${window.location.protocol}//${window.location.hostname}${
      process.env.REACT_APP_SOCKET_PORT
        ? `:${process.env.REACT_APP_SOCKET_PORT}`
        : ""
    }/`;
  if (!host.endsWith("/")) {
    host += "/";
  }
  console.log(host);
  activitySession = activitySession.replace(/(^\/+)|(\/+$)/g, "");
  return `${host}ws/${activitySession}`;
}

export const websocketModel: WebsocketModel = {
  socket: undefined,
  authState: "pending",
  authError: undefined,
  token: undefined,

  _authLoading: action((state, payload) => {
    console.info("Authing...");
    if (state.socket) {
      state.socket.disconnect();
      state.socket = undefined;
    }
    state.authState = "authing";
  }),
  _authSuccess: action((state, payload) => {
    console.info("Authed!");
    state.authState = "authed";
    state.socket = payload.socket;
    state.token = payload.token;
  }),
  _authError: action((state, payload) => {
    console.info("Auth error:", payload);
    state.authState = "error";
    state.authError = payload;
  }),
  auth: thunk(async (actions, payload, { injections, fail }) => {
    actions._authLoading();

    const ws = io(getSocketURL(payload.activitySession), {
      transports: ["websocket"],
      auth: { username: payload.username },
    });
    ws.once("connect", () => {
      // TODO: Figure out correct way to get this typed properly
      // @ts-ignore
      const userId = ws.auth["username"];
      if (!userId) {
        actions._authError("No auth id received");
        fail("Socket missing required auth information");
      } else {
        actions._authSuccess({ socket: ws, token: userId });
      }
    }); // TODO: How to get a token?
    ws.once("connect_error", (err) => {
      console.error("WS connect error:", err); // TODO: Debug: Remove this once seeing what it is
      actions._authError(err.message);
    });
  }),
  tryOpportunisticAuth: thunk(
    async (actions, payload, { injections, fail, getState }) => {
      const state = getState();
      if (state.token) {
        console.log("Attempting auth using stored token");
        actions.auth({
          username: state.token,
          activitySession: payload.activitySession,
        });
      }
    },
  ),
};
