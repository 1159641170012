import { action, Action, thunk, Thunk, ThunkOn, thunkOn } from "easy-peasy";
import { Injections, StoreModel } from ".";
import {
  ActivityRating,
  ActivitySuggestion,
  ActivityType,
  SessionActivity,
  SessionCurrentActivity,
} from "../../model";

export interface SessionModel {
  currentActivity: SessionCurrentActivity | undefined;
  activities: SessionActivity[];
  activitySuggestions: ActivitySuggestion[] | undefined;

  // Current Activity
  createActivity: Action<SessionModel, void>;
  updateCurrentActivity: Action<
    SessionModel,
    // Partial<Pick<SessionCurrentActivity, "name" | "type" | "rating">>
    Partial<SessionCurrentActivity>
  >;
  _saveCurrentActivity: Action<SessionModel, void>;
  saveCurrentActivity: Thunk<SessionModel, void, Injections, StoreModel>;

  // Remote Activities
  // Remote-remote
  addActivity: Action<SessionModel, SessionActivity>;
  addActivityRating: Action<
    SessionModel,
    { user: string; activityId: string; rating: ActivityRating }
  >;
  setActivitySuggestions: Action<SessionModel, ActivitySuggestion[]>;
  // Local-remote
  rateActivity: Thunk<
    SessionModel,
    { activityId: string; rating: ActivityRating },
    Injections,
    StoreModel
  >;

  onWebsocketConnect: ThunkOn<SessionModel, Injections, StoreModel>;
  onCurrentActivityChange: ThunkOn<SessionModel, Injections, StoreModel>;
}

export const sessionModel: SessionModel = {
  currentActivity: undefined,
  activities: [],
  activitySuggestions: undefined,

  createActivity: action((state, payload) => {
    if (state.currentActivity) {
      console.warn("Creating new activity when one already exists");
    }
    state.currentActivity = {
      name: "",
      type: ActivityType.Plain,
    };
  }),
  updateCurrentActivity: action((state, payload) => {
    if (!state.currentActivity) {
      console.error("Trying to update current activity when there isn't one");
      return;
    }
    if (payload.name !== undefined) {
      state.currentActivity.name = payload.name;
    }
    if (payload.type !== undefined) {
      state.currentActivity.type = payload.type;
    }
    if (payload.rating !== undefined) {
      state.currentActivity.rating = payload.rating;
    }
    if (payload.icon !== undefined) {
      state.currentActivity.icon = payload.icon;
    }
    if (payload.image !== undefined) {
      state.currentActivity.image = payload.image;
    }
  }),
  _saveCurrentActivity: action((state, payload) => {
    // TODO: Add fake local version, replace with real one when it comes in?
    //       If we were gonna do that, it'd probably make more sense to use
    //       socketio's response feature, but that's even more effort...
    // state.activities.unshift({
    //   id: "FAKEID",
    //   ratings: { FAKECURRENTUSER: state.currentActivity.rating },
    //   ...state.currentActivity,
    // });
    state.currentActivity = undefined;
    state.activitySuggestions = undefined;
  }),
  saveCurrentActivity: thunk(
    async (actions, payload, { injections, getStoreState, fail }) => {
      const storeState = getStoreState();
      const websocketState = storeState.websocket;
      if (websocketState.authState !== "authed" || !websocketState.socket) {
        console.error(
          "Trying to save current activity, but websocket isn't authed",
        );
        fail("Not authed");
        return;
      }
      if (
        !storeState.session.currentActivity ||
        !storeState.session.currentActivity.rating
      ) {
        console.warn("Trying to save current activity, but it's invalid");
        fail("Invalid currentActivity");
        return;
      }
      websocketState.socket.emit(
        "activity:add",
        storeState.session.currentActivity,
      );
      actions._saveCurrentActivity();
    },
  ),

  setActivitySuggestions: action((state, payload) => {
    if (!state.currentActivity) {
      console.debug(
        "Received suggestions without a current activity - ignoring.",
      );
      return;
    }
    state.activitySuggestions = payload;
  }),

  rateActivity: thunk((actions, payload, { injections, getStoreState }) => {
    const storeState = getStoreState();
    const websocketState = storeState.websocket;
    if (websocketState.authState !== "authed" || !websocketState.socket) {
      console.error(
        "Trying to save current activity, but websocket isn't authed",
      );
      fail("Not authed");
      return;
    }
    websocketState.socket.emit("activity:rate", {
      activityId: payload.activityId,
      rating: payload.rating,
    });
  }),

  addActivity: action((state, payload) => {
    state.activities.unshift(payload);
  }),

  addActivityRating: action((state, payload) => {
    // console.log("addActivityRating", payload);
    const activity = state.activities.find(
      (activity) => activity.id === payload.activityId,
    );
    if (!activity) {
      console.warn("Got rating for unknown activity:", payload.activityId);
      return;
    }
    // TODO: Try just setting the field on the existing object - it's this way because I was debugging something
    activity.ratings = { ...activity.ratings, [payload.user]: payload.rating };
  }),

  onWebsocketConnect: thunkOn(
    (actions, storeActions) => storeActions.websocket._authSuccess,
    async (actions, target) => {
      const ws = target.payload.socket;
      ws.on("activities:all", (data) => {
        for (let activity of data.reverse()) {
          actions.addActivity(activity);
        }
      });
      ws.on("activity:add", (data) => {
        actions.addActivity(data);
      });
      ws.on("activity:rate", (data) => {
        actions.addActivityRating({
          user: data["userId"],
          activityId: data["activityId"],
          rating: data["rating"],
        });
      });
    },
  ),

  onCurrentActivityChange: thunkOn(
    (actions, storeActions) => actions.updateCurrentActivity,
    async (actions, target, { getStoreState }) => {
      // This action can be called for a lot of things - we only care about name updates
      if (target.payload.name === undefined) {
        return;
      }
      const searchTerm = target.payload.name;
      // Don't make a request for an empty string
      if (!searchTerm) {
        actions.setActivitySuggestions([]);
        return;
      }
      // The backend uses a trigram search, so don't bother sending anything
      // smaller. We'll keep the existing suggestions though.
      if (searchTerm.length < 3) {
        return;
      }
      // TODO: Debounce
      const storeState = getStoreState();
      const wsState = storeState.websocket;
      if (wsState.authState !== "authed" || !wsState.socket) {
        console.debug("Not searching, websocket not authed");
        return;
      }
      const sock = wsState.socket;
      let type: string | undefined = storeState.session.currentActivity?.type;
      // Plain doesn't make a whole lot of sense for suggestions, so we're using
      // it as "auto", i.e. everything.
      if (type === ActivityType.Plain) {
        type = undefined;
      }
      sock.emit(
        "activities:suggest",
        { searchTerm: searchTerm, type: type },
        (response: ActivitySuggestion[]) => {
          actions.setActivitySuggestions(response);
        },
      );
    },
  ),
};
