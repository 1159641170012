export enum ActivityRating {
  Love = "love",
  Like = "like",
  Would = "would",
  No = "no",
}

export enum ActivityType {
  Plain = "plain",
  Steam = "steam",
}

export interface Activity {
  name: string;
  type: ActivityType;
  icon?: string;
  image?: string;
}

export interface SessionActivity extends Activity {
  id: string;
  ratings: { [key: string]: ActivityRating };
}

export interface SessionCurrentActivity extends Activity {
  rating?: ActivityRating;
}

export type ActivitySuggestion = Activity & {
  typedId: string;
};
