import React, { useCallback } from "react";
import "./Home.css";

export interface HomeProps {}

export const Home: React.FunctionComponent<HomeProps> = ({}) => {
  const createList = useCallback(() => {
    window.location.pathname = `/session-${crypto.randomUUID()}`;
  }, []);

  return (
    <div className="Home">
      <h1>What the fuck we doin'?</h1>
      <button onClick={createList}>Create List</button>
    </div>
  );
};
