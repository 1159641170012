import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./ActivityCreate.css";
import {
  ActivityRating,
  ActivitySuggestion,
  ActivityType,
  SessionCurrentActivity,
} from "../model";
import { ActivityRatingBar } from "./ActivityRatingBar";
import {
  Combobox,
  ComboboxItem,
  ComboboxPopover,
  useComboboxState,
} from "ariakit/combobox";
import clsx from "clsx";
import { ActivityIcon } from "./ActivityIcon";

export interface ActivityCreateProps {
  activity?: SessionCurrentActivity;
  activitySuggestions?: ActivitySuggestion[];
  onCreate: () => void;
  onSave: () => void;
  onSet: (
    activitySet: Partial<
      Pick<SessionCurrentActivity, "name" | "type" | "rating">
    >,
  ) => void;
}

export const ActivityCreate: React.FunctionComponent<ActivityCreateProps> = ({
  activity,
  activitySuggestions,
  onCreate: onCreateUpper,
  onSave,
  onSet,
}) => {
  const [hasSeenGuide, setHasSeenGuide] = useState(false);
  const [selectedType, setSelectedType] = useState(
    activity?.type || ActivityType.Plain,
  );

  const onCreate = useCallback(() => {
    setHasSeenGuide(true);
    onCreateUpper();
  }, [onCreateUpper]);

  // const onTextInput = useCallback(
  //   (event: ChangeEvent<HTMLInputElement>) => {
  //     if (!activity) {
  //       onCreate();
  //       onSet({ type: selectedType });
  //     }
  //     onSet({ name: event.target.value });
  //   },
  //   [onSet, onCreate, !!activity, selectedType],
  // );

  const onTextInput2 = useCallback(
    (value: string) => {
      if (!activity) {
        onCreate();
        onSet({ type: selectedType });
      }
      const suggestion = activitySuggestions?.find(
        (suggestion) => suggestion.name === value,
      );
      if (suggestion) {
        onSet(suggestion);
      } else {
        onSet({ name: value });
      }
    },
    [onSet, onCreate, !!activity, selectedType, activitySuggestions],
  );

  const onTypeChange = useCallback(
    (type: ActivityType) => {
      // if (!activity) {
      //   onCreate();
      // }
      setSelectedType(type);
      if (!activity) return;
      onSet({ type: type });
    },
    [onSet, onCreate, !!activity],
  );

  const onRatingChange = useCallback(
    (rating: ActivityRating) => {
      if (!activity) {
        onCreate();
      }
      onSet({ rating: rating });
    },
    [onSet, !!activity, onCreate],
  );

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSave();
    },
    [onSave],
  );

  const comboboxState = useComboboxState({
    sameWidth: true,
    setValue: onTextInput2,
  });

  // // TODO: HACK: Workaround for the shitty combobox
  // useEffect(() => {
  //   comboboxState.setValue(activity?.name || "");
  // }, [activity?.name]);
  //
  // useEffect(() => {
  //   onTextInput2(comboboxState.value);
  // }, [onTextInput2, comboboxState.value]);
  // // TODO: ENDHACK

  const isValidActivity = activity?.name && activity?.rating !== undefined;

  return (
    <div className="ActivityCreate">
      <form
        className={clsx(
          "ActivityCreate--name",
          hasSeenGuide && "ActivityCreate--combine",
        )}
        onSubmit={onSubmit}
      >
        <ActivityTypeSelector
          selectedType={activity?.type}
          onChange={onTypeChange}
        />
        {/*<input*/}
        {/*  type="text"*/}
        {/*  onChange={onTextInput}*/}
        {/*  value={activity?.name ?? ""}*/}
        {/*/>*/}
        {/* TODO: Replace this combobox with a custom one - need to allow
                  actually selecting an object, rather than just text. Then, we
                  can sete the type, name, icon, and image on the current
                  activity. We also need it to be controlled, so we can wipe the
                  text on submit. */}
        <div className="ariakit-combobox-wrapper ActivityCreate-combobox">
          <Combobox
            className="ariakit-combobox"
            state={comboboxState}
            placeholder="Activity"
            // onChange={onTextInput}
            // value={activity?.name ?? ""}
          />
          <ComboboxPopover
            className="ariakit-combobox-popover"
            state={comboboxState}
          >
            {activitySuggestions?.map((suggestion) => (
              <ComboboxItem
                key={suggestion.typedId}
                className="ariakit-combobox-item"
                value={suggestion.name}
              >
                <ActivityIcon activity={suggestion} />
                {suggestion.name}
              </ComboboxItem>
            ))}
          </ComboboxPopover>
        </div>
        <button type="submit" disabled={!isValidActivity}>
          Add
        </button>
      </form>

      <div
        className={clsx(
          "ActivityCreateEdit-ratingbar",
          hasSeenGuide || "ActivityCreateEdit-ratingbar--guide",
        )}
      >
        {hasSeenGuide ? (
          <ActivityRatingBar
            rating={activity?.rating}
            onChange={onRatingChange}
          />
        ) : (
          <>
            <p>Enter an activity to suggest and submit!</p>
            <p>For improved suggestions, select the type first.</p>
          </>
        )}
      </div>
    </div>
  );
};

export const ActivityCreateOld: React.FunctionComponent<
  ActivityCreateProps
> = ({ activity, onCreate, onSave, onSet }) => {
  const createActivity = useCallback(
    (type?: ActivityType) => {
      onCreate();
      if (type) {
        onSet({ type: type });
      }
    },
    [onCreate],
  );

  return (
    <div className="ActivityCreate">
      {activity ? (
        <ActivityCreateEdit activity={activity} onSet={onSet} onSave={onSave} />
      ) : (
        <ActivityCreateEmpty onCreate={createActivity} />
      )}
    </div>
  );
};

interface ActivityCreateEditProps {
  activity: SessionCurrentActivity;
  onSave: ActivityCreateProps["onSave"];
  onSet: ActivityCreateProps["onSet"];
}

const ActivityCreateEdit: React.FunctionComponent<ActivityCreateEditProps> = ({
  activity,
  onSet,
  onSave,
}) => {
  const onTextInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onSet({ name: event.target.value });
    },
    [onSet],
  );

  const onTypeChange = useCallback(
    (type: ActivityType) => {
      onSet({ type: type });
    },
    [onSet],
  );

  const onRatingChange = useCallback(
    (rating: ActivityRating) => {
      onSet({ rating: rating });
    },
    [onSet],
  );

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSave();
    },
    [onSave],
  );

  return (
    <div className="ActivityCreateEdit">
      <form className="ActivityCreateEdit--name" onSubmit={onSubmit}>
        <input type="text" onChange={onTextInput} />
        <button type="submit">Add</button>
      </form>
      <ActivityTypeSelector
        selectedType={activity.type}
        onChange={onTypeChange}
      />
      <ActivityRatingBar rating={activity.rating} onChange={onRatingChange} />
    </div>
  );
};

interface ActivityCreateEmptyProps {
  onCreate: (type?: ActivityType) => void;
}

const ActivityCreateEmpty: React.FunctionComponent<
  ActivityCreateEmptyProps
> = ({ onCreate }) => {
  return (
    <div className="ActivityCreateEmpty">
      <h2>Add an item</h2>
      <ActivityTypeSelector onChange={onCreate} />
    </div>
  );
};

const ACTIVITY_TYPE_OPTIONS: Record<ActivityType, { label: string }> = {
  plain: { label: "Auto" },
  steam: { label: "Steam" },
};

interface ActivityTypeSelectorProps {
  selectedType?: ActivityType;
  onChange?: (type: ActivityType) => void;
}

const ActivityTypeSelector: React.FunctionComponent<
  ActivityTypeSelectorProps
> = ({ selectedType, onChange }) => {
  const onSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onChange && onChange(event.target.value as ActivityType);
    },
    [onChange],
  );
  return (
    <select className="ActivityTypeSelector" onChange={onSelectChange}>
      {Object.entries(ACTIVITY_TYPE_OPTIONS).map(([activityType, typeInfo]) => (
        <option key={activityType} value={activityType}>
          {typeInfo.label}
        </option>
      ))}
    </select>
  );
};

const ActivityTypeSelectorOld: React.FunctionComponent<
  ActivityTypeSelectorProps
> = ({ selectedType, onChange }) => {
  return (
    <div className="ActivityTypeSelector">
      <button
        className={clsx(
          "ActivityTypeSelector-type",
          selectedType === ActivityType.Plain &&
            "ActivityTypeSelector-type--selected",
        )}
        onClick={() => onChange && onChange(ActivityType.Plain)}
      >
        Plain
      </button>
      <button
        className={clsx(
          "ActivityTypeSelector-type",
          selectedType === ActivityType.Steam &&
            "ActivityTypeSelector-type--selected",
        )}
        onClick={() => onChange && onChange(ActivityType.Steam)}
      >
        Steam
      </button>
    </div>
  );
};
